// 采购单
import request from '@/utils/request'

// 新增初始化
export function getAddInit() {
    return request({
        url: "/api/Purchase/addInit",
        method: "get"
    })
}

// 采购单新增初始化
export function purchaseAddInit(data) {
   return request({
    url: "/api/Purchase/purchaseAddInit",
    data,
    method: "post"
   })
}

// 采购单批量新增
export function addPurchase(data) {
    return request({
      url: "/api/Purchase/batchAdd",
      data,
      method: "post"
    })
}

// 查询初始化
export function PageInit() {
    return request({
      url: "/api/Purchase/pageInit",
      method: "get"
    })
}

// 查询(分页)
export function getListPage(params) {
    return request({
        url: "/api/Purchase/listPage",
        params,
        method: "get"
    })
}

// 采购单新增初始化
export function PurchaseAddInit(data) {
    return request({
       url: "/api/Purchase/purchaseAddInit",
       data,
       method: "post"
    })
}

// 批量操作(提交审核，审核,结束入库,作废)
export function batchUpdate(data) {
    return request({
      url: "/api/Purchase/batchUpdate",
      data,
      method: "put"
    })
}

// 删除数据
export function PurchaseDelete(id) {
    return request({
      url: "/api/Purchase/" + id,
      method: "delete"
    })
}

// 编辑初始化
export function updateInit(id) {
    return request({
        url: "/api/Purchase/updateInit/" + id,
        method: 'get'
    })
}

// 获取采购单付款记录
export function getPurchasePayBill(id) {
    return request({
       url: "/api/Purchase/getPurchasePayBill/" + id,
       method: "get"
    })
}

// 获取采购单的退款记录
export function getPurchaseCollectBill(id) {
  return request({
    url: "/api/Purchase/getPurchaseCollectBill/" + id,
    method: "get"
  })
}

// 编辑采购单
export function editPurchaseOrder(data) {
    return request({
      url: "/api/Purchase",
      data,
      method: "put"
    })
}

// 根据id查看详情
export function showPurchaseDetail(id) {
    return request({
       url: "/api/Purchase/" + id,
       method: "get"
    })
}

// 批量申请付款初始化
export function batchPurchasePaymentInit(ids) {
    return request({
        url: "/api/Purchase/batchPurchasePaymentInit/" + ids,
        method: "get"
    })
}

// 批量申请付款
export function batchCreatePayBill(data) {
    return request({
       url: "/api/Purchase/batchCreatePayBill",
       data,
       method: "post"
    })
}

// 获取付款账户列表
export function getFinanceAccountType() {
    return request({
        url: "/api/Purchase/getFinanceAccount",
        method: "get"
    })
}

// 根据供货商获取收款账户
export function getSupplierAccount(supplierId) {
    return request({
       url: "/api/Purchase/getSupplierAccount/" + supplierId,
       method: "get"
    })
}

// 批量申请退款初始化
export function batchPurchaseCollectInit(ids) {
    return request({
        url: "/api/Purchase/batchPurchaseCollectInit/" + ids,
        method: "get"
    })
}

// 批量申请退款
export function batchCreateCollectBill(data) {
    return request({
       url: "/api/Purchase/batchCreateCollectBill",
       data,
       method: "post"
    })
}

// 批量删除
export function batchDeleteEvent(data) {
   return request({
       url: "/api/Purchase/batchDelete",
       data,
       method: "delete"
   })
}

// 删除采购商品
export function deletePurchaseProduct(id) {
   return request({
     url: "/api/Purchase/deletePurchaseProduct/" + id,
     method: "delete"
   })
}

// 入库初始化
export function purchaseWarehousingInit(id) {
  return request({
    url: "/api/Purchase/purchaseWarehousingInit/" + id,
    method: "get"
  })
}

// 入库 
export function purchaseWarehouse(data, curPutInStorageId) {
  return request({
    url: "/api/Purchase/purchaseWarehousing",
    data,
    headers:{
       "purchaseId": curPutInStorageId
    },
    method: "post"
  })
}

// 获取采购商品的信息、
export function getPurchaseProductInfoByPurchase(id) {
  return request({
    url: "/api/Purchase/getPurchaseProductInfoByPurchaseId/" + id,
    method: "get"
  })
}
//采购合同打印
export function contractPrint(id) {
  return request({
    url: "/api/Purchase/contractPrint/" + id,
    method: "get"
  })
}
